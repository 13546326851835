import React, { useState, useEffect } from 'react';
import './App.css';
import Logo from './assets/images/logo.png';
import Hamburger from './assets/images/hamburger.png';
import Close from './assets/images/close.png';
import { isMobile } from './mobile';

const alphabeticalName = (a, b) => {
  if (a.name > b.name) {
    return 1;
  }
  if (a.name < b.name) {
    return -1;
  }
  return 0;
};

function ListProjects({ data, filterValue, current, onChange }) {
  const entries = Object.entries(data);
  const itemsArray = entries.map(item => item[1]);
  let itemsSorted = itemsArray.sort(alphabeticalName);

  itemsSorted = !isMobile()
    ? itemsSorted
    : itemsSorted.filter(item => {
        return item.isMobile === 'true';
      });

  if (filterValue) {
    itemsSorted = itemsSorted.filter(
      item =>
        item.name.toLowerCase().indexOf(filterValue.toLowerCase()) > -1 ||
        item.tags.toLowerCase().indexOf(filterValue.toLowerCase()) > -1
    );
  }

  return itemsSorted.map((item, index) => (
    <li key={`item-${index}`} className='app__option'>
      <button
        className={`app__button ${item.url === current ? 'selected' : ''}`}
        onClick={() => {
          onChange(item);
        }}
      >
        {item.name}
      </button>
    </li>
  ));
}

function Show(props) {
  const [current, setCurrent] = useState(null);
  const [isOpen, setOpen] = useState(true);
  const [filterValue, setFilterValue] = useState('');
  const [uri, setUri] = useState(props.match.params.uri ? props.match.params.uri : '/');
  const [data, setData] = useState('');

  useEffect(() => {
    async function fetchdata() {
      const response = await fetch('data/projects.json');
      const data = await response.json();
      setData(data);
      setCurrent(selectProperty(data).url);
    }
    fetchdata();
  }, []);

  var selectProperty = function(obj) {
    var keys = Object.keys(obj);
    if(uri === '/'){
      return obj[keys[(keys.length * Math.random()) << 0]];
    }else{
     for(var val in obj){
        if(obj[val].uri === uri){
          setOpen(false);
          return obj[val];
        }
      }
    }
  };

  return (
    <div className='app'>
      <div className={`app__options ${isOpen ? 'app__options--open' : ''}`}>
        <div className='logo'>
          <img src={Logo} alt='' />
        </div>
        <input
          placeholder='Filtrar por nombre, cliente, tipo...'
          type='text'
          name='filter'
          value={filterValue}
          onChange={event => setFilterValue(event.target.value)}
        />
        <div className='burger' onClick={() => setOpen(!isOpen)}>
          {!isOpen ? (
            <button className='burger__open'>
              <img src={Hamburger} alt='' width='30' />
            </button>
          ) : (
            <button className='burger__close'>
              <img src={Close} alt='' width='30' />
            </button>
          )}
        </div>
        <ul className='app__list'>
          <ListProjects
            data={data}
            current={current}
            filterValue={filterValue}
            onChange={item => {
              setCurrent(item.url);
              setOpen(false);
              setUri(item.uri);
              props.history.push(`/${item.uri}`);
            }}
          />
        </ul>
      </div>
      {current && (
        <iframe
          title='Muestra proyecto'
          src={current}
          className='app__frame'
          frameBorder='0'
        ></iframe>
      )}
    </div>
  );
}

export default Show;
