const checkMobile = () => {
    var isMobile = false;
    if (/Mobi/.test(navigator.userAgent)) {
        isMobile = true;
    }
    return isMobile;
}

export const isMobile = checkMobile

const checkLandscape = () => {
    var isLandscape = false;
    if (window.matchMedia("(orientation: landscape)").matches) {
        isLandscape = true;
    }
    return isLandscape;
}

export const isLandScape = checkLandscape