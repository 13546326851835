import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Show from './Show';

function App() {
  return (
    <Router>
      <Switch>
        <Route
          exact
          key="home"
          path="/"
          component={Show}
        />
        <Route
          key="app"
          path="/:uri"
          component={Show}
        />
      </Switch>
    </Router>
  );
}

export default App;
